import React from 'react'
import { Link } from 'gatsby'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import BannerDefault from 'components/flex/banners/BannerDefault'
import ParseContent from 'components/shared/ParseContent'
import Image from 'components/shared/Image'
import Button from 'components/elements/Button'
import styled from 'styled-components'
// @ts-ignore
import quote from 'img/quote-single.svg'

const Content = styled(ParseContent)`
  & blockquote {
    padding: 00px 30px 0 80px;
    position: relative;

    @media (max-width: 991px) {
      padding: 10px 0 0;
    }

    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      background-image: url(${quote});
      background-size: contain;
      background-repeat: no-repeat;
      top: 0px;
      left: -15px;
      height: 80px;
      width: 80px;

      @media (max-width: 991px) {
        position: relative;
        display: block;
        top: 0;
        left: 0;
      }
    }
  }

  & h1 {
    font-size: ${(props) => props.theme.font.size['4xl']};
  }

  & h2, h3 {
    font-size: ${(props) => props.theme.font.size.xl};
    margin-bottom: 0;
  }

  & .gatsby-image-wrapper {
    display: flex;
  }
`

interface PageTemplateProps {
  data: {
    page: {
      path?: string
      title?: string
      seo: any
      // eslint-disable-next-line camelcase
      fields: GatsbyTypes.WpCaseEcd_Acffields
    }
    cases: {
      nodes: GatsbyTypes.WpCaseEcd[]
    }
  }
  type: 'ecd' | 'pfz'
}

const CaseTemplate: React.FC<PageTemplateProps> = ({
  data: {
    page: { title, seo, fields },
    cases: { nodes: cases },
  },
  type,
}) => (
  <Layout type={type}>
    <SEO seo={seo} />

    <BannerDefault
      // @ts-ignore
      fields={{
        description: `<h1 class="font-size-5xl text-white">${title}</h1>`,
        // @ts-ignore
        column: {
          description: fields.thumbnail?.clientQuote,
          textBalloon: ['ja'],
        },
      }}
      backButton={{
        to: `${type === 'ecd'
          ? `/ecd/klantverhalen/`
          : `/pluriform-zorg/klantverhalen/`
          }`,
      }}
    />

    <div className="container my-lg-5 my-3">
      <div className="row">
        <div className="col-lg-8">
          <div className="card">
            <Content content={fields.detail?.description} />
          </div>
        </div>

        <div className="col-lg-3 py-lg-0 py-4 offset-lg-1">
          <h4 className="text-tertiary text-center">Andere klantcases</h4>
          {cases.map((item, index) => (
            <Link
              to={item.uri}
              key={item.id}
              className={`card p-4 px-5 ${cases.length - 1 === index ? `mb-0` : `mb-5`
                }`}
            >
              <Image
                style={{ maxHeight: 70 }}
                image={item.acfFields?.thumbnail?.image}
                objectFit="contain"
                alt=""
                className="mb-4"
              />

              <strong className="text-tertiary mb-4 text-center">
                {item.title}
              </strong>

              <Button className="secondary font-size-base" arrow to={item.uri}>
                Lees de klantcase
              </Button>
            </Link>
          ))}
        </div>
      </div>
    </div>
  </Layout>
)

export default CaseTemplate
